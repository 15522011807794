import React from 'react';
import { CardSection } from "@kiwicom/orbit-components/lib/Card";
import Button from "@kiwicom/orbit-components/lib/Button";
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text";
import InputStepper from "@kiwicom/orbit-components/lib/InputStepper";
import {connect} from 'react-redux';
import CartImage from './CartImage';
import {updateProductInCart} from '../../redux/food';
import {config} from '../../config';

function CartItem({item,addProductToCart}) {
    function handleAddProduct(id,value){
        addProductToCart(id,value)
    }
    return (
        <CardSection
            icon={<CartImage image={config.url.API_URL+item.image} />}
            title={<Stack align="center" direction="row" justify="between" spacing="condensed"><Stack spacing="tight"><Stack align="center" direction="row" spacing="tight"><Text weight="bold">{item.name}</Text></Stack><Text size="small" type="secondary">Cost:{item.cost} Total Cost:{item.totalCost}</Text></Stack></Stack>}
            actions={< InputStepper name={item.name} 
                        size="small"
                        maxValue={5}
                        defaultValue={item.quantity}
                        minValue={0}
                        onChange={
                            (value) => handleAddProduct(item._id, value)
                        } 
                    />}
        />
    )
}

const mapDispatchToProps = (dispatch)=>({
    addProductToCart: (id,quantity)=>dispatch(updateProductInCart(id,quantity))
})
export default connect(null,mapDispatchToProps)(CartItem);
