import {persistReducer} from 'redux-persist'; //mod1
import storage from 'redux-persist/lib/storage'; //mod2
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {food} from './food';
import {user} from './user';
import {event} from './events';
import {laf} from './lostFound';
import {clean} from './clean';

const reducers = {
    user,
    food,
    event,
    laf,
    clean
};

//mod3
const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const configureStore = () => createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));