require('dotenv').config()

const prod = {
    url: {
     API_URL: "https://scs-admin.anudeepreddy.me",
   }
};
const dev = {
    url: {
        //API_URL: "https://scs-admin.anudeepreddy.me"
        API_URL: "https://3000-cf8acce0-607a-4748-a8f2-08bad2b7d734.ws-us02.gitpod.io" //V2
        //API_URL: "https://3000-eae03cae-7405-4b7d-902a-4379fe530a68.ws-us02.gitpod.io"
    }
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;