import React from 'react';
import _ from 'lodash';
const imgSrc=[
    "https://img.icons8.com/clouds/100/000000/kawaii-bread-1.png",
    "https://img.icons8.com/clouds/100/000000/cute-pumpkin.png",
    "https://img.icons8.com/clouds/100/000000/kawaii-coffee.png",
    "https://img.icons8.com/clouds/100/000000/kawaii-cupcake.png",
    "https://img.icons8.com/clouds/100/000000/kawaii-french-fries.png",
    "https://img.icons8.com/clouds/100/000000/cherry.png",
    "https://img.icons8.com/clouds/100/000000/hamburger.png",
    "https://img.icons8.com/clouds/100/000000/taco.png",
    "https://img.icons8.com/clouds/100/000000/hot-chocolate-with-marshmallows.png"
]

function RandomIcon(){
    return (
        <figure className="image 32x32">
            <img src={_.sample(imgSrc)} alt="icon"/>
        </figure>
    )
}

export default RandomIcon;