import _ from "lodash";
import axios from '../utils/axios';
import {config} from '../config';
import {toast} from "react-toastify";
//Actions goes here
// export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS"
// export const updateProducts = (products)=>({
//     type:UPDATE_PRODUCTS,
//     payload: products
// })

//Reducers goes here
export const UPDATE_LAF_REPORTS = "UPDATE_LAF_REPORTS";
export const updateLAFReports = (reports) => ({
    type: UPDATE_LAF_REPORTS,
    payload:reports
})




const initialState = {
    reports:[]
}
export const laf = (state=initialState,action) =>{
    const {type,payload} = action;
    switch(type){
        case UPDATE_LAF_REPORTS:{
            return {...state,reports:payload}
        }
        default:{
            return state;
        }
    }
}





//Thunks goes here
export const submitReport = (data) => async(dispatch,getState) => {
    axios.post(`${config.url.API_URL}/lost-and-found/report`,data).then(response =>{
        let data = response.data;
        if(data.status){
            toast.success(data.message);    
        }
        else{
            toast.warning(data.message);
        }
    }).catch(err=>{
        toast.error('⚠️'+err.toJSON().message);
    })
}

export const fetchReports = (data) => async(dispatch,getState) => {
    axios.get(`${config.url.API_URL}/lost-and-found/reports`).then(response =>{
        let data = response.data;
        if(data.status){
            dispatch(updateLAFReports(data.reports))    
        }
    }).catch(err=>{
        toast.error('⚠️'+err.toJSON().message);
    })
}


//These are custom functions
// export const filterProductsByCategory = (products,category) => {
//     return _.filter(products,{category});
// }

// export const filterOrderByStatus = (orders,status) => {
//     return _.filter(orders,{status});
// }

