import axios from '../utils/axios';
import { config } from '../config';
import { toast } from "react-toastify";
import jwtDecode from 'jwt-decode';

//actions

export const UPDATE_LOGIN_STATE = "UPDATE_LOGIN_STATE"
export const updateLoginState = (status) => ({
    type: UPDATE_LOGIN_STATE,
    payload: status
})

//reducer
const initialState = {
    loggedIn: false
}


export const user = (state=initialState,action) =>{
    const {type,payload} = action;
    switch(type){
        case UPDATE_LOGIN_STATE:{
            return {...state,loggedIn:payload}
        }
        default:{
            return state;
        }
    }
}


//thunks

export const login = (data) => async (dispatch, getState) => {
    axios.post(`${config.url.API_URL}/user/login`, data).then(response => {
        let data = response.data;
        if (data.status) {
            //toast.success(data.message);
            dispatch(updateLoginState(true));
            localStorage.setItem('token',data.accesstoken)
            localStorage.setItem('user',jwtDecode(data.accesstoken).username)
        }
        else {
            toast.warning(data.message);
            dispatch(updateLoginState(false));
        }
    }).catch(err => {
        //console.log(err.toJSON().message);
        dispatch(updateLoginState(false));
        toast.error('⚠️' + err.toJSON().message);
    })
}

export const verifyToken = () => async(dispatch,getState)=>{
    axios.get(`${config.url.API_URL}/user/isLoggedIn`).then(response => {
        let data = response.data;
        if(data.status){
            dispatch(updateLoginState(true));
        }
        else{
            dispatch(updateLoginState(false));
        }
    })
}

export const updateNotificationId = (data) => async (dispatch, getState) => {
    axios.post(`${config.url.API_URL}/notify/subscribe`, data).then(response => {
        let data = response.data;
        /*if(data.status){
            toast.success(data.message);    
        }
        else{
            toast.warning(data.message);
        }*/
    }).catch(err => {
        console.log(err.toJSON().message);
        //toast.error('⚠️'+err.toJSON().message);
    })
}