import React,{useState,useEffect} from 'react';
import PictureCard from "@kiwicom/orbit-components/lib/PictureCard";
import InputStepper from "@kiwicom/orbit-components/lib/InputStepper";
import InputStepperStateless from "@kiwicom/orbit-components/lib/InputStepper/InputStepperStateless";
import {connect} from 'react-redux';
import {updateProductInCart} from '../../redux/food';
import {config} from '../../config';

function ProductCard({item,addProductToCart,quantity}){
    //const {item}=props;
    const minValue=0,maxValue=5;
    const [value,setValue] = useState(quantity);
    function handleAddProduct(id,value){
        addProductToCart(id,value);
    }
    function onIncrement(){
        if(value<maxValue){
            handleAddProduct(item._id,value+1);
            setValue(value+1);
        }
    }
    function onDecrement(){
        if(value>minValue){
            handleAddProduct(item._id,value-1);
            setValue(value-1);
        }
    }
    return (
        <PictureCard
            image={{
            src: config.url.API_URL+item.image,
            original: "385x320",
            placeholder: "385x320"
        }}
            subTitle={item.description||""}
            title={item.name}
            actions={< InputStepperStateless name = {item.name} maxValue = {
            maxValue
        }
        minValue = {
            minValue
        }
        value = {
            quantity
        }
        onIncrement = {
            onIncrement
        }
        onDecrement = {
            onDecrement
        }
        />}>Rs. {item.cost}</PictureCard>
    )
}
const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = (dispatch)=>({
    addProductToCart: (id,quantity)=>dispatch(updateProductInCart(id,quantity))
})

export default connect(mapStateToProps,mapDispatchToProps)(ProductCard);