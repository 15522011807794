import React,{useEffect} from 'react';
import SearchCard from './SearchCard';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import {fetchReports} from '../../redux/lostFound'; 
import {connect} from 'react-redux';

function Search({fetchReports,reports}){
    useEffect(()=>{
        fetchReports();
    },[])
    return (  
        <SwipeableViews>
            {reports.map((item,key)=>(<SearchCard item={item} key={key}/>))}
        </SwipeableViews>  
    )
}

const mapStateToProps = (state) => ({
    reports: state.laf.reports
})

const mapDispatchToProps = (dispatch) => ({
    fetchReports: ()=>dispatch(fetchReports())
})


export default connect(mapStateToProps,mapDispatchToProps)(Search);