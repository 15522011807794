import React,{useState, useEffect} from 'react';
import styled from 'styled-components';
import Stack from "@kiwicom/orbit-components/lib/Stack";
import Drawer from "@kiwicom/orbit-components/lib/Drawer";
import EventCard from '../molecules/Events/EventCard';
import SwipeableViews from 'react-swipeable-views';
import {config} from '../config';
import parse from 'html-react-parser';
import {connect} from 'react-redux';
import {fetchEvents} from '../redux/events';

const MainBody = styled.div `
    padding-top:4em;
    position: relative;
    overflow-y:hidden;
`

const Header = styled.section `
    position: sticky;
`;

const Cards = styled.div `
    overflow-y: scroll;
    max-height: 30em
`

const Card = styled.div `
    padding:1em;
`

function Events({events,fetchEvents}) {
    const [viewMore,setViewMore]=useState({status:false});
    function handleViewMore(data){
        setViewMore({data,status:!viewMore.status});
        console.log({data,status:!viewMore.status})
    }
    
    useEffect(()=>{
        fetchEvents();
    },[])

    return (
        <MainBody>
            <Stack>
               <Header className="hero is-dark has-background">
                    <img 
                        alt="header-events"
                        className="hero-background is-transparent"
                        src="/event.svg"/>
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title">
                                Events
                            </h1>
                        </div>
                    </div>
                </Header>
                <SwipeableViews>
                    {events?.map((event,key)=>(<EventCard event={event} key={key}/>))}   
                </SwipeableViews>
                
            </Stack>
        </MainBody>
    )
}

const mapStateToProps = (state) => ({
    events: state.event.events
})
const mapDispatchToProps = (dispatch) => ({
    fetchEvents: ()=>dispatch(fetchEvents())
})


export default connect(mapStateToProps,mapDispatchToProps)(Events);