import React, { FC, useEffect } from 'react';
import * as serviceWorker from '../serviceWorker';
import { Alert,Notification,Button,ButtonToolbar } from 'rsuite';

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState(null);

    const onSWUpdate = (registration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    useEffect(()=>{
        if(showReload){
            open();
        }
    },[])
    function open() {
    Notification["info"]({
      title: "Update Available",
      duration: 10000000,
      description: (
        <div style={{ width: 320 }}>
          <p>We are working hard to keep you upto date. Here is an update for you. <span role="img" aria-label="update">📱</span></p>
          <br />
          <br />
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={reloadPage}
            >
              Update
            </Button>
          </ButtonToolbar>
        </div>
      )
    });
  }
    

    const reloadPage = () => {
        if(waitingWorker)
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload(true);
    };

    return null;
}

export default ServiceWorkerWrapper;