import React from 'react';
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";
import styled from 'styled-components';
import {config} from '../../config';

function SearchCard({item}) {
    return (
        <div class="max-w-sm rounded overflow-hidden shadow-lg" style={{margin:"1em"}}>
            <img class="w-full" src={`${config.url.API_URL}${item.image}`} alt={item.location} />
            <div class="px-6 py-4">
                <span class={`inline-block ${item.status?"bg-green-200":"bg-red-200"} rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2`}>{item.status?"Resolved":"Not Reviewed"}</span>
            </div>
            <div class="px-6 py-4">
                <p className="font-bold text-gray-900">Description:</p>
                <p class="text-gray-700 text-base">
                    {item.description}
                </p>
                <p className="font-bold text-gray-900">Location:</p>
                <p class="text-gray-700 text-base">
                    {item.location}
                </p>
                <p className="font-bold text-gray-900">Reported By:</p>
                <p class="text-gray-700 text-base">
                    {item._user.fullName}
                </p>
            </div>
        </div>
    )
}

export default SearchCard;