import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Stack from "@kiwicom/orbit-components/lib/Stack";
import Button from "@kiwicom/orbit-components/lib/Button"
import Card from "@kiwicom/orbit-components/lib/Card";
import Text from "@kiwicom/orbit-components/lib/Text";
import RenderInRtl from "@kiwicom/orbit-components/lib/utils/rtl/RenderInRtl";
import Illustration from "@kiwicom/orbit-components/lib/Illustration";
import Modal, { ModalHeader, ModalFooter, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import { useLocation } from 'react-router-dom';
import OrderNow from '../molecules/FoodOrder/OrderNow';
import Orders from '../molecules/FoodOrder/Orders';
import CartItem from '../molecules/FoodOrder/CartItem';
import Select from 'react-select'
import { connect } from 'react-redux';
import { fetchProducts, fetchOrders, placeOrder, postPaymentCleanUp } from '../redux/food';
import { Helmet } from "react-helmet";
import {toast} from 'react-toastify';
import Dialog from "@kiwicom/orbit-components/lib/Dialog";

const paymentOptions = [
    { value: 0, label: 'Cash' },
    { value: 1, label: 'Online Payment' }
]

const MainBody = styled.div`
    @media (min-width: 768px) {
        padding: 5em 5em 0;
    }
    padding-top:4em;
    position: relative;
`

const Header = styled.section`
`;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function FoodOrder({ isOpen, fetchItems, fetchOrders, cart, totalCost, payment, placeOrder, postPaymentCleanUp }) {
    let view = useQuery().get("view");

    const [cartState,
        setCartState] = useState(false);
    

    const [isOrderInProgress,setIsOrderInProgress] = useState(false);

    const [checkout, setCheckout] = useState(false);

    const [checkoutOptions, setCheckoutOptions] = useState(
        {
            key: "rzp_test_2kRTknX3CPb6ZZ",
            currency: "INR",
            name: "SCS",
            image: 'https://scs.anudeepreddy.me/img/logo-512.png',
            handler: async function (response) {
                setCartState(false,()=>{
                    toast.info("Order Placed. Thanks for paying online.")
                });
                postPaymentCleanUp(response);
            }
        }
    );

    function openCheckout(payment) {
        var rzp1 = new window.Razorpay({ ...checkoutOptions, amount: payment.amount, order_id: payment.orderId });
        rzp1.open();
    };

    useEffect(() => {
        if (payment.status) {
            setCheckoutOptions({ ...checkoutOptions, amount: payment.amount, order_id: payment.orderId });
            openCheckout(payment);
        }
    }, [payment]);


    const [paymentMethod, setPaymentMethod] = useState(paymentOptions[0]);

    function handlePaymentMethod(e) {
        setPaymentMethod(e);
    }
    function handleCartState() {
        setCartState(!cartState);
    }
    function handleOrder() {
        setIsOrderInProgress(true);
        placeOrder(paymentMethod.value);
        if(paymentMethod.value===0){
          setTimeout(()=>{setCartState(false)},3000);
        }
    }
    useEffect(()=>{
      if(!cartState)
      setIsOrderInProgress(false);
    },[cartState])
    return (
        <MainBody>
            {
                !isOpen && <Dialog
                    description="Canteen is Closed Right now. Come back later"
                    illustration={<Illustration name="Accommodation" size="small" />}
                    primaryAction={<Button type="primary" href="/">Home</Button>}
                    title="Closed"
                />
            }
            <div style={{ position: "fixed", right: "0", top: "20em", zIndex: "700" }} className="button is-info" onClick={handleCartState}>
                <span className="icon">
                    <i className="fas fa-shopping-cart"></i>
                </span>
            </div>
            <Stack>
                <Header className={`hero is-full is-dark has-background`}>
                    <img alt="header-food-orders" className="hero-background is-transparent" src="./food.svg" />
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title">
                                Food Orders
                            </h1>
                        </div>
                    </div>

                    <div className="hero-foot">
                        <nav className="tabs is-boxed is-fullwidth">
                            <div className="container">
                                <ul>
                                    <li className={(view == 'order-now' || view == null) ? "is-active" : null}>
                                        <a href="/order-food?view=order-now">Order Now</a>
                                    </li>
                                    <li className={(view == 'view-orders') ? "is-active" : null}>
                                        <a href="/order-food?view=view-orders">View Orders</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </Header>
                {
                    view == 'view-orders' ?
                        <Orders /> :
                        <OrderNow />
                }

            </Stack>
            {cartState && <Modal fixedFooter onClose={handleCartState}>
                <ModalHeader
                    description="place an order if you have finalised"
                    illustration={<Illustration name="Meal" size="small" />}
                    title="Get your food prepared" />
                {
                    cart.length !== 0 ? (
                        <div>
                            <ModalSection suppressed>
                                <Stack>
                                    <Text
                                        uppercase
                                        weight="bold"
                                    >
                                        Items
                                    </Text>
                                    <Card>
                                        {cart.map((item, key) => (<CartItem item={item} key={key} />))}
                                    </Card>
                                    <Card title="Total" description={`Rs. ${totalCost}`} />
                                </Stack>
                            </ModalSection>
                            <ModalSection>
                                <Stack>
                                    <Text
                                        uppercase
                                        weight="bold"
                                    >
                                        Payment Method
                                    </Text>
                                    <Select options={paymentOptions} value={paymentMethod} onChange={handlePaymentMethod} isSearchable={false}/>
                                </Stack>
                            </ModalSection>
                        </div>) :
                        <ModalSection suppressed>
                            <Text
                                uppercase
                                weight="bold"
                            >
                                Your Cart is Empty
                                    </Text>
                        </ModalSection>
                }
                <ModalFooter flex={['0 0 auto', '1 1 100%']}>
                    <Button type="secondary" onClick={handleCartState}>
                        Back
                    </Button>
                    {
                        paymentMethod.value === 1 ?
                            <Button onClick={handleOrder} fullWidth disabled={cart.length===0} loading={isOrderInProgress}>
                                Pay (Rs. {totalCost})
                        </Button> :
                            <Button onClick={handleOrder} fullWidth disabled={cart.length===0} loading={isOrderInProgress}>
                                Place Order
                        </Button>
                    }
                </ModalFooter>
            </Modal>}
        </MainBody>
    )
}

const mapStateToProps = (state) => ({
    cart: state.food.displayCart,
    totalCost: state.food.totalCost,
    payment: state.food.payment,
    isOpen: state.food.isOpen
})
const mapDispatchToProps = (dispatch) => ({
    fetchItems: () => dispatch(fetchProducts()),
    fetchOrders: () => dispatch(fetchOrders()),
    placeOrder: (paymentMethod) => dispatch(placeOrder(paymentMethod)),
    postPaymentCleanUp: (data) => dispatch(postPaymentCleanUp(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(FoodOrder);