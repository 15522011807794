import React, { useEffect,useState } from 'react';
import styled from 'styled-components';
import ProductCard from "./ProductCard";
import Select from 'react-select'
import { connect } from 'react-redux';
import _ from 'lodash';
import {fetchProducts} from '../../redux/food'


const catOptions = [
    { value: 'snacks', label: 'Snacks' },
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'lunch', label: 'Lunch' }
]
const vegOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
]
const Cards = styled.div`

`
const Card = styled.div`
    padding:1em;
`
const FilterOptions = styled.div`
    z-index: 100;
    padding: 0 1em;
    position:relative;
`


function OrderNow({ items,cart,fetchItems }) {
    
    const [itemsF,setItemsF] = useState(items) 
    const [category,setCategory] = useState(catOptions)
    const [veggie,setVeggie] = useState(vegOptions[1])
    useEffect(()=>{
        fetchItems();
    },[])

    function handleCategory(value){
        setCategory(value);
    }

    function handleVeggie(value){
        setVeggie(value)
    }

    function applyFilter(){
        let cat = []
        for(let c of category){
            cat.push(c.value);
        }
        console.log(cat);
        let tmp = _.filter(items,function(item){
            return _.includes(cat,item.category);
        })
        console.log(tmp)
        if(veggie.value){
            tmp = _.filter(tmp,{veggie: true});
        }
        setItemsF(tmp);
    }

    function removeFilter(){
        setItemsF(items);
        setVeggie(vegOptions[1]);
        setCategory(catOptions);
    }

    return (
        <React.Fragment>
            <h1 className="title is-5">Filter:</h1>
            <FilterOptions>
                <div className="columns is-multiline">
                    <div className="column is-3">
                        <h2 className="subtitle is-6" style={{ marginBottom: "0.8em" }}>Category:</h2>
                        <Select options={catOptions} onChange={handleCategory} isMulti></Select>
                    </div>
                    <div className="column is-3">

                        <h2 className="subtitle is-6" style={{ marginBottom: "0.8em" }}>Veggie:</h2>
                        <Select options={vegOptions} onChange={handleVeggie}></Select>
                    </div>
                    <div className="column is-3">

                        <div className="buttons">
                            <div className="button is-info is-light" style={{ marginTop: "1.9em" }} onClick={applyFilter}>Apply</div>
                            <div className="button is-danger is-light" style={{ marginTop: "1.9em" }} onClick={removeFilter}>Clear</div>
                        </div>
                    </div>
                </div>
            </FilterOptions>
            <Cards className="columns is-multiline">
                {
                    itemsF.map((item,key) => (<Card className="column is-3"  key={key}>
                        <ProductCard item={item} quantity={_.find(cart,{id:item._id})?.quantity||0}/>
                    </Card>))
                }
            </Cards>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    items: state.food.items,
    cart: state.food.cart
})
const mapDispatchToProps = (dispatch) => ({
    fetchItems: ()=>dispatch(fetchProducts())
})

export default connect(mapStateToProps,mapDispatchToProps)(OrderNow);