import React,{useState} from 'react';
import {connect} from 'react-redux';
import NavigationBar from "@kiwicom/orbit-components/lib/NavigationBar";
import Tile from "@kiwicom/orbit-components/lib/Tile";
import styled from "styled-components";
import Drawer from "@kiwicom/orbit-components/lib/Drawer";
import Bag from "../Bag.svg";
import Notification from "../Notification.svg";
import Setting from "../Setting.svg";
import Calendar from "../Calendar.svg";
import Stack from "@kiwicom/orbit-components/lib/Stack";
import InstallPWA from './InstallPWA';
import Button from "@kiwicom/orbit-components/lib/Button";
import {updateLoginState} from '../redux/user';
import {Redirect} from 'react-router-dom';
import Alert from "@kiwicom/orbit-components/lib/Alert";

const Flex = styled.div `
    display: flex;
    flex-direction: row;
`

function NavBar({logout}){
    const [drawer,setDrawer] = useState(false);
    const [logoutB,setLogoutB] = useState(false);
    function handleDrawer(){
        setDrawer(!drawer);
    }
    function Icon(props) {
        return (
            <div>
                <img src={props.image} alt={props.text}/>
            </div>
        )
    }

    function handleLogout(){
        setLogoutB(true);
        localStorage.clear();
        logout();
        window.location.reload();
    }

    return (
        <div>
        {logoutB && <Redirect to="/"/>}
        <NavigationBar onMenuOpen={handleDrawer}>
                    <Flex>
                        <a href="/">
                        <figure className="image is-48x48">
                            <img
                                href="/"
                                src="/img/logo-512.png"
                                alt="logo"
                                height="50px"/>
                        </figure>
                        </a>
                    </Flex>
                </NavigationBar>
                <Drawer title="Menu" onClose={handleDrawer} shown={drawer} actions={<Button loading={logoutB} type="critical" onClick={handleLogout}>Logout</Button>}>
                    <Stack>
                        <Alert
                            title={`Hello ${localStorage.getItem('user')}`}
                            ></Alert>
                        <Tile
                                title="Home"
                                href="/"
                            text = "Home" />
                        <Tile
                                description="Order your food before the lecture end and never wait in lines anymore"
                                title="Order Food"
                                href="/order-food"
                                icon={< Icon image = {
                                Bag
                            }
                            text = "order-food" />}/>
                            <Tile
                                description="Have you found something that is lost? Report it here"
                                title="Lost and found"
                                href="/lost-and-found"
                                icon={< Icon image = {
                                Notification
                            }
                            text = "lost" />}/>
                            <Tile
                                description="Report any issues with Cleanliness"
                                title="Cleanliness"
                                href="/clean-report"
                                icon={< Icon image = {
                                Setting
                            }
                            text = "clean" />}/>
                            <Tile
                                description="Get to know about the latest Events"
                                title="Events"
                                href="/events"
                                icon={< Icon image = {
                                Calendar
                            }
                            text = "Events" />}/>

                            <InstallPWA />
                    </Stack>
                </Drawer>
            </div>
    )
}
const mapDispatchToProps = (dispatch)=>({
    logout: ()=>updateLoginState(false)
})
export default connect(null,mapDispatchToProps)(NavBar);