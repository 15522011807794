import React, { useState } from 'react';
import InputField from "@kiwicom/orbit-components/lib/InputField";
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputFile from "@kiwicom/orbit-components/lib/InputFile";
import Button from "@kiwicom/orbit-components/lib/Button";
import {connect} from "react-redux";
import styled from 'styled-components';
import { submitReport } from '../../redux/lostFound';

function Report({submit}){
    const [name,setName]=useState("");
    const [location,setLocation]=useState("");
    const [description,setDescription] = useState("");
    const [contact,setContact] = useState("");

    const fileTypes = ["image/*"];
    const [selectedFile,setSelectedFile]=useState(null);
    function onFileChange(event){
        console.log(event.target.files[0] && event.target.files[0].name);
        setSelectedFile(event.target.files[0]);
    } 
    function handleFormChange(e){
        const {value} = e.target;
        const field = e.target.name;
        if(field=='name'){
            setName(value);
        }
        else if(field=='location'){
            setLocation(value);
        }
        else if(field=='description'){
            setDescription(value);
        }
        else{
            setContact(value);
        }
    }
    function submitReport(){
        let mFormData = new FormData();
        mFormData.append('name',name);
        mFormData.append('location',location);
        mFormData.append('contact',contact);
        mFormData.append('description',description);
        if(selectedFile!=null)
        mFormData.append('file',selectedFile);
        console.log("step 1")
        submit(mFormData);
    }
    return (
        <Stack>
            <InputField label="Name" placeholder="Name of the item found" name="name" onChange={handleFormChange} value={name}/>
            <InputField label="Description" placeholder="Describe the item found" onChange={handleFormChange} name="description" value={description}/>
            <InputField label="Location" placeholder="Where you found the item" onChange={handleFormChange} name="location" value={location}/>
            <InputField label="Contact" inputMode="tel" type="number" onChange={handleFormChange} name="contact" value={contact}/>
            <InputFile label="Add an Image" allowedFileTypes={fileTypes} placeholder={selectedFile && selectedFile.name||"No file selected"} onChange={onFileChange}/>
            <Button fullWidth onClick={submitReport}>Submit Report</Button>
        </Stack>
    )
}
const mapDispatchToProps = (dispatch) =>({
    submit: (data)=>dispatch(submitReport(data))
})
export default connect(null,mapDispatchToProps)(Report);