import React from 'react';
import styled from "styled-components";
import Stack from "@kiwicom/orbit-components/lib/Stack";
import PictureCard from "@kiwicom/orbit-components/lib/PictureCard";

const MainBody = styled.div `
    padding-top:4em;
`

function Home() {
    return (
        <React.Fragment>
            <MainBody>
                <Stack>
                    <PictureCard
                        image={{
                        original: "385x320",
                        src: "/food.svg"
                    }}
                        subTitle="Order your Food"
                        title="Food"
                        href="/order-food"/>
                    <PictureCard
                        image={{
                        original: "385x320",
                        src: "/found.svg"
                    }}
                        subTitle="Found something that's lost?"
                        title="Report Lost Items"
                        href="/lost-and-found"/>
                    <PictureCard
                        image={{
                        original: "385x320",
                        src: "/clean.svg"
                    }}
                        subTitle="Is your classroom not clean?"
                        title="Report Cleanliness Issues"
                        href="/clean-report"/>
                    <PictureCard
                        image={{
                        original: "385x320",
                        src: "/event.svg"
                    }}
                        subTitle="Find Events in your campus"
                        title="Events"
                        href="/events"/>
                </Stack>
            </MainBody>
        </React.Fragment>
    )
}

export default Home;