import React,{useEffect} from 'react';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import OrderCard from './OrderCard';
import {connect} from 'react-redux';
import {fetchOrders} from '../../redux/food';

function Orders({orders,fetchOrders}){
    useEffect(()=>{
        fetchOrders();
    },[])

    return (
        <Stack>
            {orders.map(order=>(<OrderCard order={order}/>))}
        </Stack>
    )
}

const mapStateToProps = (state) => ({
    orders: state.food.orders
})
const mapDispatchToProps = (dispatch) => ({
    fetchOrders: ()=>dispatch(fetchOrders())
})

export default connect(mapStateToProps,mapDispatchToProps)(Orders);