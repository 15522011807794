import _ from "lodash";
import axios from '../utils/axios';
import {config} from '../config';
import {toast} from 'react-toastify';

//Actions goes here


export const UPDATE_EVENTS = "UPDATE_EVENTS"
export const updateEvents = (events)=>({
    type:UPDATE_EVENTS,
    payload: events
})


//Reducers goes here


const initialState = {
    events:[]
}

export const event = (state=initialState,action) =>{
    const {type,payload} = action;
    switch(type){
        case UPDATE_EVENTS:{
            return {...state,events:payload}
        }
        case 'RESET':{
            return initialState
        }
        default:{
            return state;
        }
    }
}





//Thunks goes here

export const fetchEvents = () => async(dispatch,getState) => {
    axios.get(`${config.url.API_URL}/event/all`).then(response =>{
        let data = response.data;
        if(data.status){
        dispatch(updateEvents(data.events))
    };
    }).catch(err=>{
        console.log(err);
    })
}