import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import Loading from "@kiwicom/orbit-components/lib/Loading";

import {Provider} from 'react-redux';
import {configureStore} from './redux/store.js';

import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';

import * as serviceWorker from './serviceWorker';

const store = configureStore();
const persistor = persistStore(store);


ReactDOM.render(
    <Provider store={store}>
    <PersistGate
        loading={<Loading loading={true}/>}
        persistor={persistor}><App/></PersistGate>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
