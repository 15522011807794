import React,{useState} from 'react';
import styled from 'styled-components';
import Stack from "@kiwicom/orbit-components/lib/Stack";
import {useLocation} from 'react-router-dom';
import Report from '../molecules/LostAndFound/Report';
import Search from '../molecules/LostAndFound/Search';

const MainBody = styled.div `
    padding-top:4em;
    position: relative;
    overflow-y:hidden;
`

const Header = styled.section `
    position: sticky;
`;

const Cards = styled.div `
    overflow-y: scroll;
    max-height: 30em
`
const Card = styled.div `
    padding:1em;
`

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
  

function LostAndFound(){
    let view = useQuery().get("view");
    return (
        <MainBody>
            <Stack>
               <Header className="hero is-dark has-background">
                    <img 
                        alt="header-events"
                        className="hero-background is-transparent"
                        src="/lost.svg"/>
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title">
                                Lost And Found
                            </h1>
                        </div>
                    </div>
                    <div className="hero-foot">
                        <nav className="tabs is-boxed is-fullwidth">
                            <div className="container">
                                <ul>
                                    <li className={(view=='report'||view==null)?"is-active":null}>
                                        <a href="/lost-and-found?view=report">Report</a>
                                    </li>
                                    <li className={(view=='search')?"is-active":null}>
                                        <a href="/lost-and-found?view=search">Search</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </Header>
                {
                view=='search'?
                <Search/>:
                <Report/>
                }
            </Stack>
        </MainBody>
    )
}
export default LostAndFound;