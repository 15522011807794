import React from 'react';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Clock from '@kiwicom/orbit-components/lib/icons/Clock';
import CheckCircle from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import Meal from "@kiwicom/orbit-components/lib/icons/Meal";
import RandomIcon from './RandomIcon';
import TimeAgo from 'react-timeago';
import {connect} from 'react-redux';
import _ from 'lodash';

const orderStatus = {
    'processing': {
        icon: <Clock />,
        type: 'warning',
        title: 'Processing'
    },
    'delivered': {
        icon: <CheckCircle />,
        type: 'success',
        title: 'Delivered'
    },
    'ready': {
        icon: <Meal />,
        type: 'info',
        title: 'Ready'
    }
}
function OrderCard({ order,items }) {
    let badge = (
        <Stack align="center" inline justify="end">
            <Text type="secondary">Order Made: <TimeAgo date={order.orderedDate} /></Text>
            <Badge icon={orderStatus[order.orderStatus].icon} type={orderStatus[order.orderStatus].type}>{orderStatus[order.orderStatus].title}</Badge>
        </Stack>
    )
    return (
        <Card>
            <CardSection
                expandable
                header={badge}
                icon={<RandomIcon />}
            >
                {
                    order.items.map((item, key) => {
                        let itemInItems = _.find(items,{_id:item.id});
                        let totalCost = itemInItems.cost*item.quantity;
                        return (< Card
                            actions={<Badge info>{`${itemInItems.cost}x${item.quantity}=Rs. ${totalCost}`}</Badge>}
                            description={`Price: Rs. ${itemInItems.cost}`}
                            title={itemInItems.name}
                        />)
                        })
                }
            </CardSection>
        </Card>
    )
}

const mapStateToProps = (state) => ({
    items: state.food.items
})
export default connect(mapStateToProps,null)(OrderCard);