import React, { useEffect, useState } from "react";
import 'rsuite/dist/styles/rsuite-default.css';
import { Button,Panel,PanelGroup,FlexboxGrid,Avatar } from 'rsuite';


const InstallPWA = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = e => {
            e.preventDefault();
            console.log("we are being triggered :D");
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = evt => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };
    if (!supportsPWA||window.navigator.standalone||matchMedia('(display-mode: standalone)').matches) {
        return null;
    }
    return (
        <Panel shaded style={{}}>
            <FlexboxGrid justify="space-between" align="middle">
                <FlexboxGrid.Item colspan={6}><Avatar
                    circle
                    src="/img/logo-512.png"
                /></FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}><Button appearance="ghost" onClick={onClick} >Install</Button></FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
};

export default InstallPWA;