import React from 'react';

function CartImage(props) {
    return (
        <figure className="image is-48x48">
            <img src={props.image}/>
        </figure>       
            )
}
        
export default CartImage;