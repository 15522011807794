import _ from "lodash";
import axios from '../utils/axios';
import {config} from '../config';
import {toast} from 'react-toastify';

//Actions goes here
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS"
export const updateProducts = (items)=>({
    type:UPDATE_PRODUCTS,
    payload: items
})


export const UPDATE_CART = "UPDATE_CART"
export const updateCart = (cart)=>({
    type:UPDATE_CART,
    payload: cart
})

export const UPDATE_DISPLAY_CART = "UPDATE_DISPLAY_CART"
export const updateDisplayCart = (cart)=>({
    type:UPDATE_DISPLAY_CART,
    payload: cart
})

export const UPDATE_TOTAL_COST = "UPDATE_TOTAL_COST"
export const updateTotalCost = (cost)=>({
    type:UPDATE_TOTAL_COST,
    payload: cost
})

export const UPDATE_ORDERS = "UPDATE_ORDERS"
export const updateOrders = (orders)=>({
    type: UPDATE_ORDERS,
    payload: orders
})

export const UPDATE_PAYMENT_INFO = "UPDATE_PAYMENT_INFO"
export const updatePaymentInfo = (info)=>({
    type: UPDATE_PAYMENT_INFO,
    payload: info
})

export const INIT_PAYMENT_CLEANUP = "INIT_PAYMENT_CLEANUP"

export const initPaymentCleanUp = () =>({
    type:INIT_PAYMENT_CLEANUP
})

export const CANTEEN_AVAILABILITY = "CANTEEN_AVAILABILITY"
export const canteenAvailability = (status) =>({
    type: CANTEEN_AVAILABILITY,
    payload: status
})
//Reducers goes here





const initialState = {
    items:[],
    cart:[],
    totalCost:0,
    displayCart:[],
    orders:[],
    payment:{},
    isOpen: true
}
export const food = (state=initialState,action) =>{
    const {type,payload} = action;
    switch(type){
        case UPDATE_PRODUCTS:{
            return {...state,items:payload}
        }
        case UPDATE_CART:{
            return {...state,cart:payload}
        }
        case UPDATE_ORDERS:{
            return {...state,orders:payload}
        }
        case UPDATE_TOTAL_COST:{
            return {...state,totalCost:payload}
        }
        case UPDATE_DISPLAY_CART:{
            return {...state,displayCart:payload}
        }
        case UPDATE_PAYMENT_INFO:{
            return {...state,payment:payload}
        }
        case INIT_PAYMENT_CLEANUP:{
            return {...state,payment:[],cart:[],displayCart:[],totalCost:0}
        }
        case CANTEEN_AVAILABILITY:{
            return {...state,isOpen:payload}
        }
        case 'RESET':{
            return initialState
        }
        default:{
            return state;
        }
    }
}





//Thunks goes here
export const fetchProducts = () => async(dispatch,getState) => {
    axios.get(`${config.url.API_URL}/canteen/items`).then(response =>{
        let data = response.data;
        if(data.status){
            dispatch(updateProducts(data.foodItems))
            dispatch(canteenAvailability(true));
        }
        else if(data.message==='Canteen is Closed'){
            dispatch(canteenAvailability(false));
        }
    }).catch(err=>{
        console.log(err);
    })
}

const computeDisplayCart = () => async(dispatch,getState) =>{
    let displayCart = [];
    let totalCost = 0;
    let {cart,items} = getState().food;
    for(let item of cart){
        let cartItem = _.find(items,{_id:item.id});
        cartItem = {
            ...cartItem,
            totalCost: cartItem.cost*item.quantity,
            quantity: item.quantity
        }
        totalCost+=cartItem.totalCost;
        displayCart.push(cartItem);
    }
    dispatch(updateDisplayCart(displayCart));
    dispatch(updateTotalCost(totalCost));    
}

export const updateProductInCart = (id,quantity) => async(dispatch,getState) => {
    let cart = getState().food.cart;
    if(quantity>0){
        let index = _.findIndex(cart,{id});
        if(index>=0){
            cart = _.map(cart,function(item){
                return item.id===id?{id,quantity}:item;
            })
        }
        else{
            cart = [
                ...cart,
                {id,quantity}
            ]
        }
    }
    else{
        cart = _.filter(cart,function(item){
            return item.id!=id;
        })
    }
    dispatch(updateCart(cart));
    dispatch(computeDisplayCart());
}

// export const removeProductFromCart = (id) => async(dispatch,getState) =>{
//     let cart = getState().food.cart;
//     if(quantity){
//         let index = _.findIndex(cart, {id});
//         cart.splice(index,{id,quantity});
//     }
//     else{
//         cart = _.filter(cart,function(item){
//             return item.id!=id;
//         })
//     }
//     dispatch(updateCart(cart));
//     dispatch(computeDisplayCart());

// }

export const fetchOrders = () => async(dispatch,getState) =>{
    axios.get(`${config.url.API_URL}/canteen/orders`).then(response =>{
        let data = response.data;
        dispatch(updateOrders(data.orders));
    }).catch(err=>{
        toast.error(err.toJSON().message);//TODO: Toast
    })    
}

export const placeOrder = (paymentMode) => async(dispatch,getState) => {
    axios.post(`${config.url.API_URL}/canteen/order`,{paymentMode,items:getState().food.cart}).then(response =>{
        let data = response.data;
        if(data.status&&data.mode==0){
            toast.success(data.message);
            dispatch(initPaymentCleanUp());
        }
        else if(data.status){
            dispatch(updatePaymentInfo(data))
        }
        dispatch(fetchOrders());
    }).catch(err=>{
        toast.error(err.toJSON().message);
    })
}

export const postPaymentCleanUp = (paymentId) => async(dispatch,getState) => {
    console.log("hello");
    axios.post(`${config.url.API_URL}/canteen/payment/success`,{paymentId}).then(response =>{
        let data = response.data;
        if(data.status){
            toast.success(data.message);
        }
        
    })
    dispatch(initPaymentCleanUp())
}





//These are custom functions
export const filterProductsByCategory = (products,category) => {
    return _.filter(products,{category});
}

export const filterOrderByStatus = (orders,status) => {
    return _.filter(orders,{status});
}

