import React, { useEffect } from 'react';
import history from 'react-router-dom';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import Stack from "@kiwicom/orbit-components/lib/Stack";
import { config } from '../config';
import parse from 'html-react-parser';
import Breadcrumbs, { BreadcrumbsItem } from "@kiwicom/orbit-components/lib/Breadcrumbs";
import styled from 'styled-components';

const MainBody = styled.div`
    padding-top:4em;
    position: relative;
    overflow-y:hidden;
`

function Event({ events }) {
    let { id } = useParams();
    const event = _.find(events, { _id: id });
    console.log(event)
    return (
        <MainBody>
            <Stack>
                <Breadcrumbs onGoBack={console.log}>
                    <BreadcrumbsItem
                        href="/events"
                        id="rocket"
                        
                    >
                        Events
                    </BreadcrumbsItem>
                    <BreadcrumbsItem
                        href={`/events/${id}`}
                        id="rocket2"
                    >
                        {event.name}
                    </BreadcrumbsItem>
                </Breadcrumbs>
                <div className="hero is-dark has-background">
                    <img
                        alt="header-events"
                        className="hero-background is-transparent"
                        src={`${config.url.API_URL}${event.image}`} />
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title">
                                {event.name}
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="wysiwyg" style={{padding:"0 1em"}}>
                    {
                        parse(event.content)
                    }
                </div>
            </Stack>
        </MainBody>
    )
}

const mapStateToProps = (state) => ({
    events: state.event.events
})

export default connect(mapStateToProps, null)(Event);