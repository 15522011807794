import React, { useState, useEffect } from 'react';
import Stack from "@kiwicom/orbit-components/lib/Stack";
import Modal, { ModalSection, ModalHeader, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import InputField from "@kiwicom/orbit-components/lib/InputField";
import Button from "@kiwicom/orbit-components/lib/Button";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import NavBar from "./molecules/NavBar";
import { toast } from "react-toastify";
import Home from './pages/Home';
import FoodOrder from './pages/FoodOrder';
import '@fortawesome/fontawesome-free/css/all.css'
import 'bulma/css/bulma.css';
import './custom.css'
import 'react-toastify/dist/ReactToastify.css';
import Events from './pages/Events';
import Event from './pages/Event';
import LostAndFound from './pages/LostAndFound';
import Clean from './pages/Clean';
import { updateNotificationId, login, verifyToken } from './redux/user';
import UpdatePWA from './molecules/UpdatePWA';
import 'wysiwyg.css/wysiwyg.css';

function App({ isLoggedIn, updateSubscription, Login, checkLogin }) {
    useEffect(() => {
        toast.configure({
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        })
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            var OneSignal = window.OneSignal || [];
            OneSignal.push(function () {
                OneSignal.init({
                    appId: "8698221e-02a2-451e-b3cf-03874e3def7f",
                    notifyButton: {
                        enable: true,
                    },
                });
            });
            OneSignal.push(function () {
                // Occurs when the user's subscription changes to a new value.
                OneSignal.on('subscriptionChange', function (isSubscribed) {
                    console.log("The user's subscription state is now:", isSubscribed);
                    OneSignal.getUserId().then(function (userId) {
                        updateSubscription({deviceId:userId});
                    });
                });
            });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        checkLogin();
    }, []);
    const [username,
        setUsername] = useState('');
    const [password,
        setPassword] = useState('');
    function handleLogin() {
        Login({ username, password });
    }
    function handleUsername(e) {
        setUsername(e.target.value);
    }
    function handlePassword(e) {
        setPassword(e.target.value);
    }

    return (
        <React.Fragment>
            <UpdatePWA/>
            <Router>
                <NavBar /> {!isLoggedIn && <Modal fixedFooter>
                    <ModalHeader description="Login to continue" title="Login" />
                    <ModalSection>
                        <Stack>
                            <InputField
                                label="Roll Number"
                                placeholder="Your Roll Number"
                                onChange={handleUsername} />
                            <InputField
                                label="Password"
                                placeholder="Password"
                                type="password"
                                onChange={handlePassword} />
                        </Stack>
                    </ModalSection>
                    <ModalFooter flex={['0 0 auto', '1 1 100%']}>
                        <Button onClick={handleLogin} fullWidth>
                            Login
                        </Button>
                    </ModalFooter>
                </Modal>}

                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/order-food" component={FoodOrder} />
                    <Route exact path="/lost-and-found" component={LostAndFound} />
                    <Route exact path="/clean-report" component={Clean} />
                    <Route exact path="/events" component={Events} />
                    <Route exact path="/events/:id" component={Event}/>
                </Switch>
            </Router>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    isLoggedIn: state.user.loggedIn
})
const mapDispatchToProps = (dispatch) => ({
    updateSubscription: (id) => dispatch(updateNotificationId({ id })),
    Login: (data) => dispatch(login(data)),
    checkLogin: () => dispatch(verifyToken())
})
export default connect(mapStateToProps, mapDispatchToProps)(App);
